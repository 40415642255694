import { createReducer, on } from '@ngrx/store';
import { Site, SiteStats } from '../../synaps-media.types';
import { loadSiteDetail, loadSiteDetailFailure, loadSiteDetailSuccess, loadSites, loadSitesFailure, loadSitesSuccess, loadSiteStatsSuccess } from './sites.actions';

export const sitesFeatureKey = 'sites';

export interface SitesState {
  sites: Site[];
  siteDetails: { [siteId: string]: Site };
  siteStats: { [siteId: string]: SiteStats };
  loading: boolean;
  error: any;
}

export const initialState: SitesState = {
  sites: null,
  siteDetails: {},
  siteStats: {},
  loading: false,
  error: null,
};

export const sitesReducer = createReducer(
  initialState,

  // For loading the list of sites
  on(loadSites, (state) => ({
    ...state,
    loading: true,
    error: null
  })),
  on(loadSitesSuccess, (state, { sites }) => ({
    ...state,
    sites,
    loading: false,
  })),
  on(loadSitesFailure, (state, { error }) => ({
    ...state,
    loading: false,
    error,
  })),

  // For loading/updating detailed info for a specific site
  on(loadSiteDetail, (state) => ({
    ...state,
    error: null,
  })),
  on(loadSiteDetailSuccess, (state, { siteDetail }) => ({
    ...state,
    sites: state.sites.map(site => site.id === siteDetail.id ? siteDetail : site),
    siteDetails: {
      ...state.siteDetails,
      [siteDetail.serviceName]: siteDetail,
    }
  })),
  on(loadSiteDetailFailure, (state, { error }) => ({
    ...state,
    error,
  })),
  on(loadSiteStatsSuccess, (state, { siteId, siteStats }) => ({
    ...state,
    siteStats: {
      ...state.siteStats,
      [siteId]: siteStats,
    },
  }))
);
