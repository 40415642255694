import { createAction, props } from '@ngrx/store';
import { Site, SiteStats } from '../../synaps-media.types';

export const loadSites = createAction(
  '[Sites] Load Sites'
);

export const loadSitesSuccess = createAction(
  '[Sites] Load Sites Success',
  props<{ sites: Site[] }>()
);

export const loadSitesFailure = createAction(
  '[Sites] Load Sites Failure',
  props<{ error: any }>()
);

export const loadSiteDetail = createAction(
  '[Sites] Load Site Detail',
  props<{ siteId: string }>()
);

export const loadSiteDetailSuccess = createAction(
  '[Sites] Load Site Detail Success',
  props<{ siteDetail: Site }>()
);

export const loadSiteDetailFailure = createAction(
  '[Sites] Load Site Detail Failure',
  props<{ error: any }>()
);

export const loadSiteStats = createAction(
  '[Sites] Load Site Stats',
  props<{ siteId: string }>()
);

export const loadSiteStatsSuccess = createAction(
  '[Sites] Load Site Stats Success',
  props<{ siteId: string, siteStats: SiteStats }>()
);

export const loadSiteStatsFailure = createAction(
  '[Sites] Load Site Stats Failure',
  props<{ error: any }>()
);
