import { createFeatureSelector, createSelector } from '@ngrx/store';
import { SitesState, sitesFeatureKey } from './sites.reducer';

export const selectSitesState = createFeatureSelector<SitesState>(sitesFeatureKey);

export const selectAllSites = createSelector(
  selectSitesState,
  (state) => state.sites
);

// Given a siteId, select the corresponding detailed info (updated by polling)
export const selectSiteDetail = (siteId: string) => createSelector(
  selectSitesState,
  (state) => state.siteDetails[siteId] || null
);

export const selectSiteStats = (siteId: string) => createSelector(
  selectSitesState,
  (state) => state.siteStats[siteId]
);
